<template>
    <b-overlay :show="showLoader">
        <b-card class="reserve-fund mb-0">
            <b-row class="mt-2" align-v="center">
                <b-col cols="12" lg="5" class="mt-1 mt-lg-0">
                    <Select
                        v-model="filter.unit"
                        :options="units"
                        label="naziv"
                        placeholder="Enota"
                        @change="loadReserveFund,loadVoluntaryFund"
                        :customDropdownLabelFn="customLabelFunction"
                        :customSelectedLabelFn="customLabelFunction"
                    />
                </b-col>
                <b-col cols="12" lg="3" class="mt-1 mt-lg-0">
                    <rounded-button class="w-100" @click="loadReserveFund(),loadVoluntaryFund()"
                    >PRIKAŽI <fa icon="search"
                    /></rounded-button>
                </b-col>
            </b-row>
            <div v-if="data && data.length">
                <h3 class="mt-2 font-top-row">
                    Izpis sklada za objekt {{filter.unit.naziv}}
                    <!--                    {{filter.unit.naziv}}, {{filter.unit.kraj}}-->
                </h3>
            </div>
            <h1 class="text-secondary font-title">Rezervni sklad</h1>
            <my-portal-table class="mt-1" :data="data" :fields="fields">
                <template #cell(stanje_terjatev)="row">
                    <span>{{$convertFloat(row.item.rezervni_fakturirano - row.item.rezervni_placano) }}</span>
                </template>
                <template #cell(stanje_sredstev)="row">
                    <span>{{$convertFloat(row.item.rezervni_placano - row.item.rezervni_poraba) }}</span>
                </template>
            </my-portal-table>
            <div v-if="dataVoluntary.length>0" class="mt-3">
                <h1 class="text-secondary font-title">Prostovoljni sklad</h1>
                <my-portal-table class="mt-1" :data="dataVoluntary" :fields="fieldsVoluntary">
                    <!--                    <template #cell(stanje_terjatev)="row">
                        <span>{{$convertFloat(row.item.terjano - row.item.placano)}}</span>
                    </template>
                    <template #cell(stanje_sredstev)="row">
                        <span>{{$convertFloat(row.item.placano - row.item.poraba)}}</span>
                    </template>-->
                </my-portal-table>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
    import { BOverlay, BRow, BCol, BCard } from 'bootstrap-vue'
    import Select from '../../Components/Select'
    import RoundedButton from '@/views/Components/RoundedButton.vue'
    import MyPortalTable from '@/views/Components/MyPortalTable'
    export default {
        components: {
            MyPortalTable,
            BOverlay,
            BRow,
            BCol,
            Select,
            BCard,
            RoundedButton
        },
        data() {
            return {
                showLoader: false,
                filter: { unit: '' },
                objects: [],
                units:[],
                fields: [
                    //{ key: 'naziv', label: 'Objekt', sortable: false, class: 'text-left' },
                    {
                        key: 'rezervni_fakturirano',
                        label: 'Terjano (€)',
                        sortable: false,
                        class: 'text-left',
                        formatter: (val) => {
                            return this.$convertFloat(val)
                        }
                    },
                    {
                        key: 'rezervni_placano',
                        label: 'Plačano (€)',
                        sortable: false,
                        class: 'text-left',
                        formatter: (val) => {
                            return this.$convertFloat(val)
                        }
                    },
                    {
                        key: 'rezervni_poraba',
                        label: 'Poraba (€)',
                        sortable: false,
                        class: 'text-left',
                        formatter: (val) => {
                            return this.$convertFloat(val)
                        }
                    },
                    {
                        key: 'stanje_terjatev',
                        label: 'Stanje terjatev (€)',
                        sortable: false,
                        class: 'text-left'
                    },
                    {
                        key: 'stanje_sredstev',
                        label: 'Stanje sredstev (€)',
                        sortable: false,
                        class: 'text-left'
                    },
                    {
                        key: 'rezervni_depoziti',
                        label: 'Depoziti (€)',
                        sortable: false,
                        class: 'text-left',
                        formatter: (val) => {
                            return this.$convertFloat(val)
                        }
                    }
                ],
                data: [],
                fieldsVoluntary: [
                    { key: 'skupina_stroskov', label: 'Vrsta prostovoljnega sklada', sortable: false, class: 'text-left' },
                    { key: 'stanje_na_dan_1_1', label: 'Otvoritev (1.1.) (€)', sortable: false, class: 'text-left', formatter: val => { return this.$convertFloat(val) } },
                    { key: 'placila_v_odb', label: 'Plačila (€)', sortable: false, class: 'text-left', formatter: val => { return this.$convertFloat(val) } },
                    { key: 'dvigi', label: 'Dvigi (€)', sortable: false, class: 'text-left', formatter: val => { return this.$convertFloat(val) } },
                    { key: 'poraba_sred_v_obdobju', label: 'Poraba (€)', sortable: false, class: 'text-left', formatter: val => { return this.$convertFloat(val) } },
                    { key: 'stanje_sredstev', label: 'Stanje (€)', sortable: false, class: 'text-left', formatter: val => { return this.$convertFloat(val) } }
                    //{ key: 'depoziti', label: 'Depoziti', sortable: false, class: 'text-left', formatter: val => { return this.$convertFloat(val) } }
                ],
                dataVoluntary: []
            }
        },
        methods: {
            customLabelFunction(unit) {
                return `${unit.naziv}`
            },
            async loadData() {
                this.showLoader = true
                this.$scrollToTop()
                try {
                    //await this.loadObjects()
                    await this.loadUnits()
                    await this.loadReserveFund()
                    await this.loadVoluntaryFund()
                } catch (error) {
                    this.$printError(
                        `Prišlo je do napake pri nalaganju podatkov\n${error.message}`
                    )
                } finally {
                    this.showLoader = false
                }
            },
            async loadObjects() {
                const res = await this.$http.get(
                    '/api/iiportal-user/v1/e_manager/objekti'
                )
                this.objects = res.data
                this.objects.sort((a, b) => { 
                    if ((a.ulica === b.ulica && parseInt(a.hisna_stevilka) < parseInt(b.hisna_stevilka)) || (a.ulica < b.ulica)) return -1
                    else if (a.ulica > b.ulica) return 1
                    else return 0
                })

                if (this.objects.length > 0)  this.filter.unit = this.objects[0]
               
            },
            async  loadUnits() {
                const res = await this.$http.get('/api/iiportal-user/v1/e_manager/enota')
                this.units = []

                if (res.data) {
                    res.data.forEach((element) => {
                        if (element.vrsta === 'U') {
                            if (res.data.find(x => x.sifra === element.sifra && x.vrsta !== element.vrsta) === undefined) {
                                this.units.push(element)
                            }
                        } else {
                            this.units.push(element)
                        }
                    })
                }

                this.units.sort((a, b) => {
                    if ((a.ulica === b.ulica && parseInt(a.hisna_stevilka) < parseInt(b.hisna_stevilka)) || (a.ulica < b.ulica)) return -1
                    else if (a.ulica > b.ulica) return 1
                    else return 0
                })

                if (this.units.length > 0)  this.filter.unit = res.data[0]
            },
            async loadReserveFund() {
                if (!this.showLoader) this.showLoader = true

                try {
                    const res = await this.$http.get(
                        `/api/iiportal-user/v1/e_manager/rezervni_sklad/${this.filter.unit.objekt_sifra}`
                    )
                    const rezervniSklad = res.data
                    this.data = []

                    if (rezervniSklad && rezervniSklad.id !== this.$NILObjectID) {
                        this.data.push(res.data)
                    }
                } catch (error) {
                    this.$printError(
                        `Prišlo je do napake pri nalaganju podatkov\n${error.message}`
                    )
                } finally {
                    this.showLoader = false
                }
            },
            async loadVoluntaryFund() {
                if (!this.showLoader) this.showLoader = true
                try {
                    const res = await this.$http.get(`/api/iiportal-user/v1/e_manager/prostovoljni_sklad/napredno/${this.filter.unit.sifra}`)

                    this.dataVoluntary = res.data

                } catch (error) {
                    this.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>

<style>
.reserve-fund .font-top-row {
  background: #72a5d8;
  font-weight: 600;
  color: white;
  padding: 10px;
}
.reserve-fund {
  border-radius: 10px !important;
}
.reserve-fund .font-title {
  font-weight: bold;
  color: #72a5d8 !important;
}
.reserve-fund .font {
  font-weight: bold;
}
</style>
